import React from "react";
import ClientForm from "./ClientForm";

const BookForm = () => {
  return (
    <div className="clients-form">
      <ClientForm />
    </div>
  );
};

export default BookForm;
