import React from "react";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-items">
        <div className="item">
          <h1>Like to get in touch?</h1>
          <p>
            <a href="/amazon_afiliate_products">page</a>
            <a href="/promoting_gig_page">page2</a>
          </p>
          <div className="icons">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="49"
              viewBox="0 0 48 49"
              fill="none"
            >
              <circle cx="24" cy="24.1367" r="24" fill="#295F2D" />
              <path
                d="M21.6667 21.4701H19V24.1367H21.6667V32.1367H25.6667V24.1367H28.0933L28.3333 21.4701H25.6667V20.3594C25.6667 19.7221 25.7947 19.4701 26.4107 19.4701H28.3333V16.1367H25.128C22.7307 16.1367 21.6667 17.1927 21.6667 19.2141V21.4701Z"
                fill="#F0F7ED"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="49"
              viewBox="0 0 48 49"
              fill="none"
            >
              <circle cx="24" cy="24.1367" r="24" fill="#295F2D" />
              <path
                d="M32 19.6767C31.412 19.9381 30.7787 20.1141 30.1147 20.1941C30.7933 19.7874 31.312 19.1447 31.5587 18.3781C30.924 18.7541 30.2213 19.0274 29.4733 19.1741C28.876 18.5354 28.0213 18.1367 27.0787 18.1367C24.9587 18.1367 23.4013 20.1141 23.88 22.1674C21.1533 22.0301 18.7333 20.7234 17.1147 18.7381C16.2547 20.2127 16.6693 22.1434 18.1307 23.1207C17.5933 23.1034 17.088 22.9554 16.6453 22.7101C16.6093 24.2301 17.7 25.6527 19.2787 25.9701C18.8173 26.0954 18.3107 26.1247 17.796 26.0261C18.2133 27.3301 19.428 28.2781 20.8627 28.3047C19.48 29.3874 17.7427 29.8714 16 29.6661C17.4533 30.5981 19.1773 31.1407 21.0307 31.1407C27.1267 31.1407 30.5693 25.9927 30.3613 21.3754C31.004 20.9141 31.56 20.3354 32 19.6767Z"
                fill="#F0F7ED"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="49"
              viewBox="0 0 48 49"
              fill="none"
            >
              <circle cx="24" cy="24.1367" r="24" fill="#295F2D" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24 16.1367C21.8267 16.1367 21.556 16.1461 20.7013 16.1861C17.7947 16.3194 16.1813 17.9314 16.048 20.8381C16.0093 21.6927 16 21.9647 16 24.1367C16 26.3101 16.0093 26.5821 16.048 27.4354C16.1813 30.3407 17.7947 31.9554 20.7013 32.0887C21.556 32.1274 21.8267 32.1367 24 32.1367C26.1733 32.1367 26.4453 32.1274 27.3 32.0887C30.2013 31.9554 31.8213 30.3434 31.952 27.4354C31.9907 26.5821 32 26.3101 32 24.1367C32 21.9647 31.9907 21.6927 31.952 20.8381C31.8213 17.9354 30.2067 16.3181 27.3 16.1861C26.4453 16.1461 26.1733 16.1367 24 16.1367ZM24 17.5794C26.136 17.5794 26.3893 17.5874 27.2333 17.6261C29.4027 17.7247 30.4133 18.7527 30.5133 20.9047C30.5507 21.7487 30.5587 22.0007 30.5587 24.1367C30.5587 26.2727 30.5507 26.5261 30.5133 27.3687C30.4133 29.5194 29.404 30.5501 27.2333 30.6487C26.3893 30.6861 26.1373 30.6954 24 30.6954C21.864 30.6954 21.6107 30.6874 20.768 30.6487C18.5947 30.5487 17.588 29.5167 17.488 27.3687C17.4507 26.5261 17.4413 26.2727 17.4413 24.1367C17.4413 22.0007 17.4507 21.7474 17.488 20.9047C17.5867 18.7514 18.5973 17.7234 20.768 17.6247C21.6107 17.5861 21.864 17.5794 24 17.5794ZM19.892 24.1367C19.892 21.8674 21.732 20.0287 24 20.0287C26.268 20.0287 28.108 21.8687 28.108 24.1367C28.108 26.4061 26.268 28.2447 24 28.2447C21.732 28.2447 19.892 26.4061 19.892 24.1367ZM24 26.8034C22.5267 26.8034 21.3333 25.6101 21.3333 24.1367C21.3333 22.6647 22.5267 21.4701 24 21.4701C25.472 21.4701 26.668 22.6634 26.668 24.1367C26.668 25.6101 25.472 26.8034 24 26.8034ZM27.3093 19.8674C27.3093 19.3367 27.74 18.9074 28.2693 18.9074C28.8013 18.9074 29.2307 19.3367 29.2307 19.8674C29.2307 20.3981 28.8 20.8274 28.2693 20.8274C27.7387 20.8274 27.3093 20.3967 27.3093 19.8674Z"
                fill="#F0F7ED"
              />
            </svg>
          </div>
        </div>
        <div className="item">
          <div className="row">
            <div className="row-item">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                >
                  <path
                    d="M13.6869 32.8834C12.1742 33.8607 10.3716 34.2867 8.5822 34.0897C6.79281 33.8925 5.12573 33.0844 3.86134 31.8009L2.75595 30.7184C2.27134 30.2219 2 29.5553 2 28.861C2 28.1668 2.27134 27.4999 2.75595 27.0036L7.44768 22.3538C7.93922 21.87 8.60081 21.599 9.28999 21.599C9.97915 21.599 10.6408 21.87 11.1323 22.3538C11.628 22.8392 12.2937 23.111 12.9869 23.111C13.6801 23.111 14.3458 22.8392 14.8414 22.3538L22.2107 14.9733C22.4565 14.7307 22.6517 14.4415 22.785 14.1226C22.9182 13.8038 22.9868 13.4615 22.9868 13.1159C22.9868 12.7702 22.9182 12.428 22.785 12.1091C22.6517 11.7902 22.4565 11.5011 22.2107 11.2584C21.7276 10.7662 21.4569 10.1035 21.4569 9.41331C21.4569 8.72307 21.7276 8.06047 22.2107 7.56817L26.8778 2.89383C27.3735 2.40847 28.0391 2.13672 28.7323 2.13672C29.4257 2.13672 30.0913 2.40847 30.5871 2.89383L31.6679 4.00092C32.9492 5.26726 33.7561 6.9369 33.953 8.72904C34.1499 10.5212 33.7245 12.3266 32.7485 13.8416C27.6637 21.347 21.1939 27.8101 13.6869 32.8834Z"
                    stroke="#F0F7ED"
                    stroke-width="2.46154"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <div className="">
                <h2>Call Us</h2>
                <p>+2348065109764</p>
              </div>
            </div>

            <div className="row-item">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                >
                  <path
                    d="M20.2857 31.4941H2V9.77978L11.1429 1.77979L20.2857 9.77978V31.4941Z"
                    stroke="#F0F7ED"
                    stroke-width="2.28571"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20.2857 31.4941H31.7143V15.4941H20.2857"
                    stroke="#F0F7ED"
                    stroke-width="2.28571"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.1429 31.4943V26.9229"
                    stroke="#F0F7ED"
                    stroke-width="2.28571"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.71429 20.0654H14.5714"
                    stroke="#F0F7ED"
                    stroke-width="2.28571"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.71429 13.2085H14.5714"
                    stroke="#F0F7ED"
                    stroke-width="2.28571"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <div className="">
                <h2>Nigeria Address</h2>
                <p>Futa North gate, Akure.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
